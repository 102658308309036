.App {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.App-header {
    text-align: center;
    background-color: #282c34;
    flex: 0 0 10vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    color: white;
}

.App-table {
    text-align: left;
    margin: 20px;
    padding: 0 10px;
    background-color: cornsilk;
    border: 1px solid #d5d9d9;
    border-radius: 8px;
    box-shadow: rgba(213, 217, 217, .5) 0 2px 5px 0;
}

.App-table h2 {
    padding-left: 10px;
}

table {
    border-collapse: collapse;
    width: 100%;
    color: #333;
    font-family: Arial, sans-serif;
    font-size: 14px;
    text-align: left;
    border-radius: 10px;
    overflow: hidden;
}

table th {
    font-weight: bold;
    padding: 10px;
    text-transform: uppercase;
    border-bottom: 1px solid #ccc;
}

table td {
    padding: 10px;
    border-bottom: 1px solid #ccc;
}

.App-actions {
    margin: 20px;
    padding: 20px;
    background-color: cornsilk;
    border: 1px solid #d5d9d9;
    border-radius: 8px;
    box-shadow: rgba(213, 217, 217, .5) 0 2px 5px 0;
}

.App-button:hover {
    background-color: #f7fafa;
}

.App-button:focus {
    border-color: #008296;
    box-shadow: rgba(213, 217, 217, .5) 0 2px 5px 0;
    outline: 0;
}

.App-button {
    background-color: #fff;
    border: 1px solid #d5d9d9;
    border-radius: 8px;
    box-shadow: rgba(213, 217, 217, .5) 0 2px 5px 0;
    box-sizing: border-box;
    color: #0f1111;
    cursor: pointer;
    display: inline-block;
    font-family: "Amazon Ember",sans-serif;
    font-size: 13px;
    line-height: 29px;
    padding: 0 10px 0 11px;
    position: relative;
    text-align: center;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    min-width: 100px;
}

.App-body {
    text-align: center;
    flex: 1 1 auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.App-body p {
    max-width: 500px;
}

.App-footer {
    text-align: center;
    justify-content: center;
    align-content: center;
    color: white;
    background-color: #282c34;
    flex: 0 0 5vh;
}

.App-link {
    color: #61dafb;
}

